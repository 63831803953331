import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { getNextWeek } from "@/utils/date";
import { computed, ref } from "vue";
import { z } from "zod";

export const ZFahrer = z.object({
  id: z.string(),
  vorname: z.string(),
  nachname: z.string(),
  anzeige_name: z.string(),
  montag: z.boolean(),
  dienstag: z.boolean(),
  mittwoch: z.boolean(),
  donnerstag: z.boolean(),
  freitag: z.boolean(),
  samstag: z.boolean(),
  sonntag: z.boolean(),
  bemerkung: z.string(),
  antwort_erfasst: z.boolean(),
});

const ZWochenplan = z.object({
  id: z.string(),
  fahrer: z.array(ZFahrer),
});

const ZData = z
  .object({
    plan: ZWochenplan.nullable(),
    week: z.string(),
    wochen: z.record(z.string(), z.number()),
  })
  .optional();

export type IFahrer = z.infer<typeof ZFahrer>;
export type IWochenplan = z.infer<typeof ZWochenplan>;
export type IData = z.infer<typeof ZData>;

export function useWochenplan() {
  const client = useClient();
  const { request, setQueryParam, queryParams } =
    client.get("/query/wochenplan/");
  if (!queryParams.value.woche) setQueryParam("woche", getNextWeek());

  const data = ref<IData>();

  const query = useGet(request, data, queryParams);

  const plan = computed(() => data.value?.plan);
  const wochen = computed(() => data.value?.wochen || []);

  useValidation(data, ZData);

  return {
    data,
    plan,
    wochen,
    query,
    setQueryParam,
    queryParams,
  };
}

import useClient from "@/api/client";
import { IFahrer, ZFahrer } from "./useWochenplan";
import { computed, ref } from "vue";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { z } from "zod";

const ZData = z
  .object({
    fahrer: ZFahrer,
    von: z.string(),
    bis: z.string(),
    woche: z.number(),
  })
  .optional();

export type IData = z.infer<typeof ZData>;

export function useWochenplanFahrer(wochenplanId: string, fahrerId: string) {
  const client = useClient();
  const { request, urlParams } = client.get("/query/wochenplan/fahrer/{}/{}/");

  urlParams.value = [wochenplanId, fahrerId];

  const data = ref<IData>();
  const fahrer = computed<IFahrer | undefined>(() => data.value?.fahrer);

  const query = useGet(request, data);

  useValidation(data, ZData);

  return {
    fahrer,
    urlParams,
    data,
    query,
  };
}

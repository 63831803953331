import useClient from "@/api/client";
import { IFahrer } from "../types/fahrer";
import { Ref, computed, ref, watch } from "vue";
import useQuery from "@/composables/useQuery";

export interface IFahrerForm {
  name: string;
  value: string;
}

export function useFahrer(when?: Ref<boolean>) {
  const client = useClient();
  const fahrer = ref<IFahrer[]>([]);
  const { request, setQueryParam } = client.get("/query/fahrer/");
  const query = useQuery(request, fahrer);

  if (when)
    watch(when, (newVal) => {
      if (newVal) query();
    });
  else query();

  const fahrerForm = computed<IFahrerForm[]>(() => {
    const f: IFahrerForm[] = fahrer.value.map((f) => ({
      name: f.anzeige_name,
      value: f.id,
    }));
    return f;
  });

  return {
    fahrer,
    fahrerForm,
    setQueryParam,
    query,
  };
}

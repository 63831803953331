const isObjectAndHasAttr = (obj: unknown, attr: string): boolean => {
  return Object.prototype.hasOwnProperty.call(obj, attr);
};

const isObjectAndEmpty = (obj: unknown): boolean => {
  if (obj === null) return false;
  if (typeof obj !== "object") return false;
  return Object.keys(obj).length === 0;
};

export { isObjectAndHasAttr, isObjectAndEmpty };

<script setup lang="ts">
import { useWochenplan } from "../api/useWochenplan";
import QueryBar from "@/components/QueryBar.vue";
import { computed } from "vue";
import WochenplanErstellen from "../actions/WochenplanErstellen.vue";
import { TableGenerator } from "lorga-ui";
import FahrerHinzufuegen from "../actions/FahrerHinzufuegen.vue";
import WochenplanTage from "../components/WochenplanTage.vue";
import FahrerEntfernen from "../actions/FahrerEntfernen.vue";
import VerfuegbarkeitAnpassen from "../actions/VerfuegbarkeitAnpassen.vue";
import WochenplanLoeschen from "../actions/WochenplanLoeschen.vue";
import EmailsSenden from "../actions/EmailsSenden.vue";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/20/solid";

const { queryParams, setQueryParam, plan, wochen, query } = useWochenplan();

const filterHints = computed(() => [
  {
    label: "Woche",
    name: "woche",
    type: "select",
    options: Object.entries(wochen.value).map(([iso, week]) => ({
      label: week.toString(),
      value: iso,
    })),
    addons: ["prev", "next"],
  },
]);

const woche = computed<string>(() => queryParams.value.woche as string);

const head = [
  {
    name: "Fahrer",
    key: "anzeige_name",
  },
  {
    name: "Verfügbarkeit",
    key: "tage",
  },
  { name: "", key: "action" },
];
</script>

<template>
  <div class="p-5 px-6 bg-gray-500 print:p-0 print:bg-white">
    <QueryBar
      class="mt-2"
      :filter-hints="filterHints"
      :set-query-param="setQueryParam"
      :query-params="queryParams"
    />
    <div class="mt-5 [&_td]:py-2">
      <TableGenerator
        :data="plan && plan.fahrer ? plan.fahrer : []"
        :head="head"
      >
        <template #head-action>
          <WochenplanErstellen v-if="!plan" :query="query" :woche="woche" />
          <FahrerHinzufuegen
            v-if="plan"
            :query="query"
            :wochenplan-id="plan.id"
          />
          <EmailsSenden v-if="plan" :wochenplan-id="plan.id" />
          <WochenplanLoeschen
            v-if="plan"
            :query="query"
            :wochenplan-id="plan.id"
          />
        </template>
        <template #anzeige_name="{ i }">
          <div class="flex space-x-2">
            <router-link
              v-if="plan"
              :to="{
                name: 'wochenplan-fahrer',
                params: { wochenplanId: plan.id, fahrerId: i.id },
              }"
            >
              {{ i.anzeige_name }}
            </router-link>
            <CheckCircleIcon
              v-if="i.antwort_erfasst"
              class="w-5 h-5 text-slate-500"
            />
            <XCircleIcon v-else class="w-5 h-5 text-slate-400" />
          </div>
        </template>
        <template #tage="{ i }">
          <WochenplanTage :fahrer="i" />
        </template>
        <template #action="{ i }">
          <VerfuegbarkeitAnpassen
            v-if="plan"
            :query="query"
            :fahrer="i"
            :wochenplan-id="plan.id"
          />
          <FahrerEntfernen
            v-if="plan"
            :query="query"
            :fahrer-id="i.id"
            :wochenplan-id="plan.id"
            :fahrer-name="i.anzeige_name"
          />
        </template>
      </TableGenerator>
    </div>
  </div>
</template>

import { types } from "lorga-ui";

export const wochenplanFahrerFields: types.FormField[] = [
  {
    label: "Montag",
    name: "montag",
    type: "singlecheckbox",
  },
  {
    label: "Dienstag",
    name: "dienstag",
    type: "singlecheckbox",
  },
  {
    label: "Mittwoch",
    name: "mittwoch",
    type: "singlecheckbox",
  },
  {
    label: "Donnerstag",
    name: "donnerstag",
    type: "singlecheckbox",
  },
  {
    label: "Freitag",
    name: "freitag",
    type: "singlecheckbox",
  },
  {
    label: "Samstag",
    name: "samstag",
    type: "singlecheckbox",
  },
  {
    label: "Sonntag",
    name: "sonntag",
    type: "singlecheckbox",
  },
  {
    label: "Bemerkung",
    name: "bemerkung",
    type: "text",
    required: false,
  },
];

import useClient from "@/api/client";
import { Ref, ref, watch } from "vue";
import useQuery from "@/composables/useQuery";

export function useBegleittypen(when?: Ref<boolean>) {
  const client = useClient();
  const begleittypen = ref<string[]>([]);
  const { request } = client.get("/query/begleittypen/");
  const query = useQuery(request, begleittypen);

  if (when)
    watch(when, (newVal) => {
      if (newVal) query();
    });
  else query();

  return {
    begleittypen,
  };
}

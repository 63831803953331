<script setup lang="ts">
import { TableGenerator } from "lorga-ui";
import useAuftraege from "../api/useAuftraege";
import SearchBar from "@/components/SearchBar.vue";

const { auftraege, queryParams, setQueryParam } = useAuftraege();

const head = [
  { name: "Abrechnungsnummer", key: "abrechnungsnummer" },
  { name: "Fahrer", key: "fahrer" },
  { name: "Fahrzeugtyp", key: "fahrzeugtyp" },
  { name: "Fahrgestellnummer", key: "fahrgestellnummer" },
  { name: "Kennzeichen", key: "kennzeichen" },
  { name: "Abholdatum", key: "abholdatum" },
  { name: "Abholort", key: "abholort" },
  { name: "Zielort", key: "zielort" },
  // { name: "", key: "actions" },
];
</script>

<template>
  <div class="p-5 px-6 bg-gray-500 print:p-0 print:bg-white">
    <SearchBar
      :hints="[]"
      :set-query-param="setQueryParam"
      :query-params="queryParams"
    />
    <TableGenerator :head="head" :data="auftraege"></TableGenerator>
  </div>
</template>

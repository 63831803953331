<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{ query: () => void }>();
const { query } = toRefs(props);

const fields: types.FormField[] = [
  {
    label: "PK",
    name: "pk",
    type: "number",
    required: true,
  },
  {
    label: "Firma",
    name: "firma",
    type: "text",
    required: true,
  },
  {
    label: "Anzeigename",
    name: "anzeigename",
    type: "text",
    required: false,
  },
  {
    label: "Ansprechpartner",
    name: "ansprechpartner",
    type: "text",
    required: true,
  },
];

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Erstellen"
      title="Kunden Erstellen"
      :data="{ action: 'kunden.erstellen' }"
      :fields="fields"
      :request="commandRequest"
    />
    Kunden Erstellen
  </ButtonNormal>
</template>

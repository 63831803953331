<script setup lang="ts">
import { computed, toRefs } from "vue";
import { IFahrer } from "../api/useWochenplan";

const props = defineProps<{ fahrer: IFahrer }>();
const { fahrer } = toRefs(props);

const WOCHENTAGE: (keyof IFahrer)[] = [
  "montag",
  "dienstag",
  "mittwoch",
  "donnerstag",
  "freitag",
  "samstag",
  "sonntag",
];

const tage = computed(() => {
  return WOCHENTAGE.map((tag) => {
    return {
      tag,
      verfuegbar: fahrer.value[tag] as boolean,
    };
  });
});
</script>

<template>
  <div>
    <ul class="flex items-center space-x-2">
      <li
        v-for="t in tage"
        :key="t.tag"
        class="font-medium text-center border-2 rounded w-7"
        :class="{
          'bg-gray-200 text-gray-600 border-gray-400': t.verfuegbar,
          'bg-gray-50 text-gray-400 border-gray-100': !t.verfuegbar,
        }"
      >
        <span v-if="fahrer.antwort_erfasst">
          {{ t.tag.slice(0, 2) }}
        </span>
        <span v-else>?</span>
      </li>
    </ul>
    <p>
      <i>
        {{ fahrer.bemerkung }}
      </i>
    </p>
  </div>
</template>

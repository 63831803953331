<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  name: string;
  istInformiert: boolean;
  hatDokumente: boolean;
}>();

const ampel = computed<"red" | "orange" | "green" | "black">(() => {
  if (props.istInformiert && props.hatDokumente) return "green";
  if (props.istInformiert && !props.hatDokumente) return "orange";
  if (!props.istInformiert && !props.hatDokumente) return "red";
  return "black";
});
</script>

<template>
  <span>
    <b class="font-semibold">
      {{ name }}
      <span
        class="inline-block w-3 h-3 bg-black rounded-full"
        :class="{
          'bg-green-700': ampel === 'green',
          'bg-yellow-500': ampel === 'orange',
          'bg-red-600': ampel === 'red',
        }"
      ></span>
    </b>
  </span>
</template>

<script setup lang="ts">
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { computed } from "vue";
import { useFahrer } from "../composables/useFahrer";
import useCmd from "@/composables/useCmd";
import { wochenplanFahrerFields } from "../utils/wochenplan";

const props = defineProps<{ query: () => void; wochenplanId: string }>();

const { commandRequest, commandModalOpen } = useCmd(props.query);

const { fahrerForm } = useFahrer(commandModalOpen);

const fields = computed<types.FormField[]>(() => [
  {
    label: "Fahrer",
    name: "fahrer_id",
    type: "searchselect",
    required: false,
    options: fahrerForm.value,
  },
  {
    name: "alle",
    type: "slot",
  },
  {
    name: "verfuegbarkeit",
    type: "fields",
    fields: wochenplanFahrerFields,
  },
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setAlle = (data: Record<string, any>) => {
  data.verfuegbarkeit = {
    montag: true,
    dienstag: true,
    mittwoch: true,
    donnerstag: true,
    freitag: true,
    samstag: true,
  };
};
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    Fahrer Hinzufügen
  </ButtonNormal>
  <ModalCreate
    ref="modal"
    v-model="commandModalOpen"
    title="Fahrer Hinzufügen"
    submit="Hinzufügen"
    :fields="fields"
    :request="commandRequest"
    :data="{
      action: 'wochenplan.fahrer_hinzufuegen',
      wochenplan_id: wochenplanId,
    }"
  >
    <template #alle="{ data }">
      <ButtonNormal kind="action" @click="() => setAlle(data)">
        Alle Wochentage
      </ButtonNormal>
    </template>
  </ModalCreate>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/features/auth/store";
import NavBarItem from "./NavBarItem.vue";

const authStore = useAuthStore();
const logoutUrl = import.meta.env.VITE_API_URL + "/logout";
const loginUrl =
  import.meta.env.VITE_API_URL +
  "/login" +
  "?next=" +
  encodeURIComponent(window.location.href);
</script>

<template>
  <nav
    class="fixed print:hidden w-[calc(100%-16rem)] right-0 top-0 z-10 bg-slate-300 text-slate-900"
  >
    <div class="flex items-center justify-between h-6 pl-2">
      <div>
        <NavBarItem><router-link to="/">Start</router-link></NavBarItem>
      </div>
      <div
        class="flex items-center justify-end h-6 divide-x-2 divide-slate-400"
      >
        <template v-if="authStore.isAuthenticated && authStore.user">
          <NavBarItem>{{ authStore.user.name }}</NavBarItem>
          <NavBarItem><a :href="logoutUrl">Logout</a></NavBarItem>
        </template>
        <template v-else>
          <NavBarItem><a :href="loginUrl">Login</a></NavBarItem>
        </template>
      </div>
    </div>
  </nav>
</template>

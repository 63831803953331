<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{ query: () => void }>();
const { query } = toRefs(props);

const fields: types.FormField[] = [
  {
    label: "PK",
    name: "pk_nummer",
    type: "number",
    required: true,
  },
  {
    label: "Anzeige Name",
    name: "anzeige_name",
    type: "text",
    required: true,
  },
  {
    label: "Typ",
    name: "typ",
    type: "select",
    required: true,
    options: [
      { name: "Intern", value: "intern" },
      { name: "Extern", value: "extern" },
    ],
  },
];

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Erstellen"
      title="Fahrer Erstellen"
      :data="{ action: 'fahrer.erstellen' }"
      :fields="fields"
      :request="commandRequest"
    />
    Fahrer Erstellen
  </ButtonNormal>
</template>

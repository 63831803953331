<script setup lang="ts">
import { computed, toRefs } from "vue";
import { getTime } from "@/features/disposition/utils/begleitfahrt";
import {
  IAuftrag,
  IBegleitfahrt,
} from "@/features/abrechnung/api/useAbrechnungsplan";
import FahrerAbgerechnetListe from "./FahrerAbgerechnetListe.vue";

const props = defineProps<{
  begleitfahrt: IBegleitfahrt;
  auftragVonDatum: string | null;
  auftrag: IAuftrag;
  query: () => void;
}>();

const { begleitfahrt, auftragVonDatum } = toRefs(props);

const time = computed<string>(() => {
  return getTime(begleitfahrt.value, auftragVonDatum.value);
});
</script>

<template>
  <div class="">
    <p>
      {{ begleitfahrt.von.name }} -> {{ begleitfahrt.nach.name }} ({{ time }})
    </p>
    <div class="flex items-baseline space-x-1">
      <span>{{ begleitfahrt.typ }}:</span>
      <FahrerAbgerechnetListe
        :fahrer="begleitfahrt.abzurechnende_fahrer"
        :auftrag="auftrag"
        :query="query"
      />
    </div>
  </div>
</template>

import { createApp } from "vue";
import App from "./App.vue";
import "./main.postcss";
import { createPinia } from "pinia";
import routes from "./routes";
import { createRouter, createWebHistory } from "vue-router";

// vue
const app = createApp(App);

// store
const pinia = createPinia();
app.use(pinia);

// router
const router = createRouter({ history: createWebHistory(), routes });
app.use(router);

// mount
app.mount("#app");

// info
console.info(`version: ${import.meta.env.VITE_VERSION}`);

<script setup lang="ts">
import { QueryParams, SetQueryParam } from "@/api/client";
import { PlusIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { computed, toRefs } from "vue";

interface ISearchHint {
  label: string;
  name: string;
  type?: string;
  options?: {
    label: string;
    value: string | boolean | number;
  }[];
}

const props = defineProps<{
  queryParams: QueryParams;
  setQueryParam: SetQueryParam;
  disableSearch?: boolean;
  hints?: (ISearchHint | undefined)[];
}>();
const { setQueryParam, hints, queryParams } = toRefs(props);

const internalHints = computed<ISearchHint[]>(() => {
  if (!hints?.value) return [];
  return hints.value.filter((h) => h !== undefined) as ISearchHint[];
});

const inputs = computed(() => {
  const i: { [key: string]: string | string[] } & { base: string[] } = {
    base: [],
  };
  const searches = queryParams.value["search"] || [];
  if (!Array.isArray(searches)) return i;
  searches.forEach((search) => {
    const splitted = search.split(":");
    if (splitted.length === 2) {
      const key = splitted[0];
      const value = splitted[1];
      i[key] = value;
    } else {
      i.base.push(splitted.join(":"));
    }
  });
  if (i.base.length === 0) i.base.push("");
  return i;
});

const updateSearch = (index: number, value: string, search: string[]) => {
  if (index > -1 && !value) search.splice(index, 1);
  else if (index > -1) search.splice(index, 1, value);
  else search.push(value);
};

const setGeneral = (index: number, value: string) => {
  const search = queryParams.value["search"] || [];
  if (!Array.isArray(search)) return;

  const oldValue = inputs.value.base[index];
  const queryParamsIndex = search.indexOf(oldValue);

  updateSearch(queryParamsIndex, value, search);

  setQueryParam.value("search", search);
};

const setSpecific = (key: string, value: string) => {
  const search = queryParams.value["search"] || [];
  if (!Array.isArray(search)) return;

  const index = search.findIndex((s) => s.startsWith(key));

  updateSearch(index, value ? `${key}:${value}` : "", search);

  setQueryParam.value("search", search);
};

const add = () => {
  const search = queryParams.value["search"] || [];
  if (!Array.isArray(search)) return;
  search.push("");
  if (search.length === 1) search.push("");
  setQueryParam.value("search", search);
};

const remove = (value: string) => {
  const search = queryParams.value["search"] || [];
  if (!Array.isArray(search)) return;

  const index = search.indexOf(value);
  if (index > -1) search.splice(index, 1);

  setQueryParam.value("search", search);
};

const reset = () => {
  setQueryParam.value("search", []);
};
</script>

<template>
  <div>
    <div v-if="!disableSearch" class="print:hidden">
      <div class="flex items-center space-x-2">
        <template v-for="(input, index) in inputs.base" :key="index">
          <div
            v-if="index > 0"
            class="flex items-center justify-center border border-transparent rounded w-7 h-7 text-slate-700 bg-slate-100"
          >
            &amp;
          </div>
          <div class="flex items-center h-8 border rounded bg-slate-200">
            <input
              :value="input"
              placeholder="Search..."
              type="text"
              name="search"
              class="h-8 px-2 text-base border border-transparent rounded-l focus:outline-none bg-gray-50 hover:bg-gray-50 focus:bg-gray-50"
              @keyup.enter="
                setGeneral(index, ($event.target as HTMLInputElement).value)
              "
            />
            <button
              class="flex items-center justify-center pl-[2px] w-8 h-8 rounded-r hover:bg-slate-100/30 border-slate-500 text-slate-500 bg-slate-200 focus:outline-none active:bg-slate-100/70"
              type="button"
              @click="remove(input)"
            >
              <XMarkIcon class="w-6 h-6" />
            </button>
          </div>
        </template>
        <button
          class="flex items-center justify-center rounded w-7 h-7 hover:bg-slate-100 border-slate-500 text-slate-500 bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-slate-500 focus:ring-offset-1 focus:ring-slate-200"
          type="button"
          @click="() => add()"
        >
          <PlusIcon class="w-6 h-6" />
        </button>

        <button
          class="flex items-center justify-center rounded w-7 h-7 hover:bg-slate-100 border-slate-500 text-slate-500 bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-slate-500 focus:ring-offset-1 focus:ring-slate-200"
          type="button"
          @click="reset"
        >
          <XMarkIcon class="w-6 h-6" />
        </button>
      </div>
    </div>
    <div
      v-if="internalHints"
      class="flex flex-wrap items-center mt-2 gap-x-4 gap-y-2 print:hidden"
    >
      <template v-for="(hint, index) in internalHints" :key="index">
        <div class="flex items-center h-8 border rounded bg-slate-200">
          <label
            class="max-w-full ml-2 text-sm font-medium text-slate-800"
            :for="`search-bar-${hint.name}`"
          >
            {{ hint.label }}:
          </label>
          <template v-if="hint.type === 'text'">
            <input
              :id="`search-bar-${hint.name}`"
              :value="inputs[hint.name]"
              :name="hint.name"
              class="h-full py-0 pl-1 pr-0 ml-2 text-base border border-transparent bg-slate-100 focus:outline-none"
              @keyup.enter="
                setSpecific(
                  hint.name,
                  ($event.target as HTMLInputElement).value,
                )
              "
            />
            <button
              class="flex items-center justify-center pl-[2px] w-8 h-8 rounded-r active:bg-slate-100/70 hover:bg-slate-100/30 border-slate-500 text-slate-500 bg-slate-200 focus:outline-none"
              type="button"
              @click="setSpecific(hint.name, '')"
            >
              <XMarkIcon class="w-6 h-6" />
            </button>
          </template>
          <select
            v-else
            :id="`search-bar-${hint.name}`"
            :value="inputs[hint.name]"
            :name="hint.name"
            class="py-1 pl-1 pr-0.5 ml-2 text-base border border-transparent bg-slate-100 focus:outline-none w-52"
            @change="
              setSpecific(hint.name, ($event.target as HTMLInputElement).value)
            "
          >
            <template v-for="option in hint.options" :key="option">
              <option :value="option.value">{{ option.label }}</option>
            </template>
          </select>
          <button
            class="flex items-center justify-center pl-[2px] w-8 h-8 rounded-r hover:bg-slate-100/30 border-slate-500 text-slate-500 bg-slate-200 focus:outline-none active:bg-slate-100/70"
            type="button"
            @click="setSpecific(hint.name, '')"
          >
            <XMarkIcon class="w-6 h-6" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

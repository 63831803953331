<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalDelete } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{
  query: () => void;
  kundenId: string;
  kundenName: string;
}>();
const { query } = toRefs(props);

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="delete" @click="commandModalOpen = true">
    <ModalDelete
      v-model="commandModalOpen"
      submit="Löschen"
      title="Kunden Löschen"
      :data="{
        action: 'kunden.loeschen',
        kunde_id: kundenId,
      }"
      :request="commandRequest"
    >
      Soll der Kunde '{{ kundenName }}' wirklich gelöscht werden?
    </ModalDelete>
    Löschen
  </ButtonNormal>
</template>

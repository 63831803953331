<script setup lang="ts">
import { ButtonNormal, ModalUpdate, types } from "lorga-ui";
import useCmd from "@/composables/useCmd";
import { IFahrer } from "../api/useWochenplan";
import { wochenplanFahrerFields } from "../utils/wochenplan";

const props = defineProps<{
  query: () => void;
  wochenplanId: string;
  fahrer: IFahrer;
}>();

const { commandRequest, commandModalOpen } = useCmd(props.query);

const fields: types.FormField[] = [
  {
    name: "alle",
    type: "slot",
  },
  ...wochenplanFahrerFields,
];

const setAlle = (data: Record<string, unknown>) => {
  data.montag = true;
  data.dienstag = true;
  data.mittwoch = true;
  data.donnerstag = true;
  data.freitag = true;
  data.samstag = true;
};
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    Verfügbarkeit Anpassen
  </ButtonNormal>
  <ModalUpdate
    ref="modal"
    v-model="commandModalOpen"
    title="Verfügbarkeit Anpassen"
    submit="Anpassen"
    :fields="fields"
    :request="commandRequest"
    :data="{
      action: 'wochenplan.verfuegbarkeit_anpassen',
      bemerkung: fahrer.bemerkung,
      wochenplan_id: wochenplanId,
      fahrer_id: fahrer.id,
      montag: fahrer.montag,
      dienstag: fahrer.dienstag,
      mittwoch: fahrer.mittwoch,
      donnerstag: fahrer.donnerstag,
      freitag: fahrer.freitag,
      samstag: fahrer.samstag,
      sonntag: fahrer.sonntag,
    }"
  >
    <template #alle="{ data }">
      <ButtonNormal kind="action" @click="() => setAlle(data)">
        Alle Wochentage
      </ButtonNormal>
    </template>
  </ModalUpdate>
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";
import DispositionFahrerListe from "./DispositionFahrerListe.vue";
import { getTime } from "../utils/begleitfahrt";
import { IBegleitfahrt } from "../api/useDispositionsplan";

const props = defineProps<{
  begleitfahrt: IBegleitfahrt;
  auftragVonDatum: string | null;
}>();

const { begleitfahrt, auftragVonDatum } = toRefs(props);

const time = computed<string>(() => {
  return getTime(begleitfahrt.value, auftragVonDatum.value);
});
</script>

<template>
  <div class="">
    <p>
      {{ begleitfahrt.von.name }} -> {{ begleitfahrt.nach.name }} ({{ time }})
    </p>
    <p>
      {{ begleitfahrt.typ }}:
      <DispositionFahrerListe
        :fahrer="begleitfahrt.fahrer ? [begleitfahrt.fahrer] : []"
      />
    </p>
  </div>
</template>

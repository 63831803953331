<script setup lang="ts">
import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { TableGenerator } from "lorga-ui";
import { ref } from "vue";
import KundenErstellen from "../actions/KundenErstellen.vue";
import KundenBearbeiten from "../actions/KundenBearbeiten.vue";
import SearchBar from "@/components/SearchBar.vue";
import KundenLoeschen from "../actions/KundenLoeschen.vue";

interface IKunde {
  id: string;
  anzeige_name: string;
  vorname: string;
  nachname: string;
}

const kunden = ref<IKunde[]>();
const client = useClient();
const { request, setQueryParam, queryParams } = client.get("/query/kunden/");
const query = useGet(request, kunden, queryParams);

const head = [
  { name: "PK", key: "pk_nummer" },
  { name: "Firma", key: "firma" },
  { name: "Ort", key: "ort" },
  { name: "Debitorennummer", key: "debitorennummer" },
  { name: "Anzeigename", key: "anzeigename" },
  { name: "Ansprechpartner", key: "ansprechpartner" },
  { name: "", key: "action" },
];
</script>

<template>
  <div class="p-5 px-6 bg-slate-500">
    <SearchBar :set-query-param="setQueryParam" :query-params="queryParams" />
    <TableGenerator :head="head" :data="kunden">
      <template #head-action>
        <KundenErstellen :query="query" />
      </template>
      <template #action="{ i }">
        <KundenBearbeiten
          :query="query"
          :kunden-id="i.id"
          :kunden-firma="i.firma"
          :kunden-anzeige-name="i.anzeige_name"
          :kunden-ansprechpartner="i.ansprechpartner"
        />
        <KundenLoeschen
          :query="query"
          :kunden-id="i.id"
          :kunden-name="i.firma"
        />
      </template>
    </TableGenerator>
  </div>
</template>

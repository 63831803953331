import { Ref } from "vue";
import useQuery from "./useQuery";

function useGet<
  Type,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  Fn extends (...args: any[]) => Promise<Type>,
>(
  getFunc: Fn,
  obj: Ref<Type | undefined>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...watching: Ref<any>[]
): () => void {
  const query = useQuery(getFunc, obj, ...watching);

  query();

  return query;
}

export default useGet;

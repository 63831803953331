import { Ref, computed } from "vue";
import { IFahrerForm } from "./useFahrer";
import { types } from "lorga-ui";
import useFahrerFields from "./useFahrerFields";

const useBegleitfahrtFields = (
  begleittypen: Ref<string[]>,
  fahrer: Ref<IFahrerForm[]>,
) => {
  const { fahrerFields } = useFahrerFields(fahrer);

  const begleitfahrtFields = computed<types.FormField[]>(() => {
    const f: types.FormField[] = [
      {
        label: "Begleitfahrt Typ",
        name: "typ",
        type: "datalist",
        required: true,
        options: begleittypen.value,
      },
      { label: "Begleitfahrt Von", name: "von", type: "text", required: true },
      {
        label: "Begleitfahrt Nach",
        name: "nach",
        type: "text",
        required: true,
      },
      {
        label: "Begleitfahrt Abholdatum",
        name: "abholdatum",
        type: "datetime-local",
        required: false,
      },
      {
        type: "fields",
        name: "fahrer",
        fields: fahrerFields.value,
      },
      {
        label: "Kennzeichen",
        name: "kennzeichen",
        type: "text",
        required: false,
      },
      {
        label: "Mehrwertsteuerpflichtig",
        name: "mehrwertsteuerpflichtig",
        type: "singlecheckbox",
      },
    ];
    return f;
  });

  return {
    begleitfahrtFields,
  };
};

export default useBegleitfahrtFields;

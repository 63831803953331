<script setup lang="ts">
import { computed } from "vue";
import useAbrechnungMonatsansicht from "../api/useAbrechnungMonatsansicht";
import QueryBar from "@/components/QueryBar.vue";
import AbrechnungAuftragBreit from "../components/AbrechnungAuftragBreit.vue";

const { plan, setQueryParam, queryParams, query } =
  useAbrechnungMonatsansicht();

const filterHints = computed(() => [
  {
    label: "Monat",
    name: "monat",
    type: "select",
    options: plan.value
      ? Object.entries(plan.value?.monate).map(([number, date]) => ({
          label: number,
          value: date,
        }))
      : [],
  },
  {
    label: "Filter",
    name: "status_filter",
    type: "select",
    options: [
      { label: "Alle", value: "" },
      {
        label: "Fehlt Noch & Nicht abgerechnet",
        value: "fehlt_noch_und_nicht_abgerechnet",
      },
      { label: "Fehlt Noch", value: "fehlt_noch" },
      { label: "Nicht Abgerechnet", value: "nicht_abgerechnet" },
    ],
  },
]);
</script>

<template>
  <div class="p-10 bg-gray-500 print:p-0 print:bg-white">
    <div class="flex flex-col space-y-4">
      <QueryBar
        :filter-hints="filterHints"
        :query-params="queryParams"
        :set-query-param="setQueryParam"
      />

      <ul class="max-w-2xl space-y-2">
        <li v-for="auftrag in plan?.auftraege" :key="auftrag.id">
          <AbrechnungAuftragBreit :auftrag="auftrag" :query="query" />
        </li>
      </ul>
    </div>
  </div>
</template>

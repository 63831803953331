<script setup lang="ts">
import useClient from "@/api/client";
import useCommand from "@/composables/useCommand";
import { ButtonNormal, ModalConfirm } from "lorga-ui";

defineProps<{
  wochenplanId: string;
}>();

const client = useClient();

const { request } = client.post("/command/");
const { commandRequest, commandModalOpen } = useCommand(request);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    E-Mails Senden
  </ButtonNormal>
  <ModalConfirm
    v-model="commandModalOpen"
    title="E-Mails Senden"
    submit="Senden"
    :request="commandRequest"
    :data="{
      action: 'wochenplan.emails_senden',
      wochenplan_id: wochenplanId,
    }"
  >
    Willst du wirklich alle Fahrer benachrichtigen?
  </ModalConfirm>
</template>

import useClient from "@/api/client";
import useQuery from "@/composables/useQuery";
import { useValidation } from "@/composables/useValidation";
import { computed, ref } from "vue";
import { z } from "zod";

const ZAuftrag = z.object({
  id: z.string(),
  fahrer: z.string(),
  fahrgestellnummer: z.string(),
  kennzeichen: z.string(),
  abholort: z.string(),
  abholdatum: z.string(),
  zielort: z.string(),
  fahrzeugtyp: z.string(),
  abrechnungsnummer: z.string(),
});

export const ZListe = z
  .object({
    auftraege: z.array(ZAuftrag),
  })
  .optional();

export type IAuftrag = z.infer<typeof ZAuftrag>;
export type IListe = z.infer<typeof ZListe>;

const useAuftraege = () => {
  const client = useClient();
  const plan = ref<IListe>({ auftraege: [] });

  const { request, setQueryParam, queryParams } = client.get(
    "/query/auftraege/listenansicht/",
  );
  const query = useQuery(request, plan, queryParams);

  useValidation(plan, ZListe);

  const auftraege = computed(() => plan.value?.auftraege);

  return {
    query,
    setQueryParam,
    queryParams,
    plan,
    auftraege,
  };
};

export default useAuftraege;

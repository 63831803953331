<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalDelete } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{
  query: () => void;
  fahrerId: string;
  fahrerName: string;
}>();
const { query } = toRefs(props);

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="delete" @click="commandModalOpen = true">
    <ModalDelete
      v-model="commandModalOpen"
      submit="Löschen"
      title="Fahrer Löschen"
      :data="{
        action: 'fahrer.loeschen',
        fahrer_id: fahrerId,
      }"
      :request="commandRequest"
    >
      Soll der Fahrer '{{ fahrerName }}' wirklich gelöscht werden?
    </ModalDelete>
    Löschen
  </ButtonNormal>
</template>

<template>
  <div class="p-5">
    <div class="flex-col flex items-center justify-center p-5 border w-64 h-64">
      <ButtonNormal kind="action" @click="showSuccess()">
        Show Success Alert
      </ButtonNormal>
      <ButtonNormal kind="action" @click="showError()">
        Show Error Alert
      </ButtonNormal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAlertStore } from "@/store/alert";
import { ButtonNormal } from "lorga-ui";

const alertStore = useAlertStore();

const showSuccess = () => {
  alertStore.createAlert({
    heading: "Test Success",
    type: "success",
    message: "This is a success alert.",
  });
};

const showError = () => {
  alertStore.createAlert({
    heading: "Test Error",
    type: "error",
    message: "This is an error alert.",
  });
};
</script>

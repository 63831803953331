<template>
  <NavBar v-if="userStore.isAuthenticated" />
  <SideNav
    v-if="userStore.isAuthenticated"
    :show="showNav"
    :toggle="toggleNav"
  />
  <main
    class="inline-block [&>div]:min-h-[calc(100vh-24px)] relative print:min-h-auto print:w-full print:mt-0"
    :class="{
      'w-[calc(100%-16rem)] mt-6': showNav && userStore.isAuthenticated,
      'w-full': !showNav || !userStore.isAuthenticated,
    }"
  >
    <router-view></router-view>
  </main>
  <AlertList
    :alerts="alertStore.alerts"
    @close="alertStore.closeAlert($event)"
  />
</template>

<script lang="ts" setup>
import { useAlertStore } from "@/store/alert";
import { AlertList } from "lorga-ui";
import { useAuthStore } from "./features/auth/store";
import SideNav from "./components/SideNav.vue";
import NavBar from "./components/NavBar.vue";
import { ref } from "vue";

const alertStore = useAlertStore();

const showNav = ref(true);
const toggleNav = () => {
  showNav.value = !showNav.value;
};

// check login status
const userStore = useAuthStore();
userStore.updateData();
</script>

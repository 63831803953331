/* eslint-disable @typescript-eslint/no-explicit-any, no-unused-vars */
import axios, { AxiosInstance } from "axios";
import { inject, InjectionKey, provide, ref, Ref, unref } from "vue";

const clientKey = Symbol() as InjectionKey<Client>;

// type ErrorHandler = (error: AxiosError) => Promise<void>;
type CallerInstance = AxiosInstance;
type UrlParamType =
  | string
  | number
  | undefined
  | Ref<string>
  | Ref<number>
  | Ref<null>
  | Ref<undefined>;
export type QueryParams = Record<
  string,
  number | string | string[] | null | boolean | undefined
>;
type UrlParams = UrlParamType[] | UrlParamType;
export type SetQueryParam = (
  key: string,
  value: string | number | string[] | null | boolean | undefined,
) => void;

class Client {
  caller: CallerInstance;

  constructor(caller: CallerInstance) {
    this.caller = caller;
  }

  /**
   * Generated by ChatGPT in case you wanted to know.
   * Build a url string by replacing placeholders in the url with arguments.
   * Each placeholder is represented by {} in the url string.
   * Also /%7B%7D/ stands for {} in the url string.
   * @param url The url string, containing placeholders to be replaced.
   * @param params The arguments to replace the placeholders in the url.
   * @returns The formatted url string.
   */
  private buildUrlFromParams(url: URL, params: UrlParams): URL {
    const paramsArray = Array.isArray(params) ? [...params] : [params];

    return new URL(
      url.href.replace(/%7B%7D/g, function (): string {
        return String(unref(paramsArray.shift()));
      }),
    );
  }

  /**
   * Generated by Github Copilot in case you wanted to know.
   * Generate me a function that adds query parameters to a url. And the
   * input is a dictionary object.
   * @param url The url string.
   * @param queryParams The dictionary object.
   * @returns The formatted url string.
   */
  private buildUrlWithQueryParams(url: URL, queryParams: QueryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      if (value === undefined) continue;
      if (Array.isArray(value)) {
        value.forEach((v) => url.searchParams.append(key, String(v)));
        continue;
      }
      url.searchParams.append(key, String(value));
    }
    return url;
  }

  private buildUrl(
    url: string,
    urlParams: UrlParams,
    queryParams: QueryParams,
  ) {
    const url1 = new URL(url, import.meta.env.VITE_API_URL as string);
    const url2 = this.buildUrlFromParams(url1, urlParams);
    const url3 = this.buildUrlWithQueryParams(url2, queryParams);
    return url3.href;
  }

  get<R = any>(
    url: string,
  ): {
    request: () => Promise<R>;
    queryParams: Ref<QueryParams>;
    urlParams: Ref<UrlParams>;
    setQueryParam: SetQueryParam;
  } {
    const urlParams = ref<UrlParams>();

    const queryParams = ref<QueryParams>({});
    const setQueryParam = (
      key: string,
      value: string | number | string[] | null | boolean | undefined,
    ) => {
      queryParams.value = { ...queryParams.value, [key]: value };
      if (value === null || value === undefined) delete queryParams.value[key];
    };

    const request = () =>
      this.caller
        .get(this.buildUrl(url, urlParams.value, queryParams.value))
        .then((r) => r.data);

    return { request, queryParams, urlParams, setQueryParam };
  }

  post<D extends Record<string, any>>(
    url: string,
  ): {
    request: (data?: D) => Promise<void>;
    queryParams: Ref<QueryParams>;
    urlParams: Ref<UrlParams>;
    setQueryParam: SetQueryParam;
  } {
    const urlParams = ref<UrlParams>();

    const queryParams = ref<QueryParams>({});
    const setQueryParam: SetQueryParam = (
      key: string,
      value: string | number | string[] | null | boolean | undefined,
    ) => {
      queryParams.value = { ...queryParams.value, [key]: value };
    };

    const request = (data?: D) =>
      this.caller
        .post(this.buildUrl(url, urlParams.value, queryParams.value), data)
        .then(() => {
          // ignore
        });

    return { request, queryParams, urlParams, setQueryParam };
  }
}

export default function useClient() {
  let client: Client | undefined = inject(clientKey, undefined);

  if (!client) {
    const $axios = axios.create();
    $axios.defaults.baseURL = import.meta.env.VITE_API_URL as string;
    $axios.defaults.withCredentials = true;
    $axios.defaults.xsrfHeaderName = "x-csrftoken";
    $axios.defaults.xsrfCookieName = "csrftoken";

    client = new Client($axios);

    provide(clientKey, client);
  }

  return client;
}

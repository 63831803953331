import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { getMondayDate } from "@/utils/date";
import { computed, ref } from "vue";
import { z } from "zod";

const ZPunkt = z.object({
  name: z.string(),
  datum: z.string().nullable(),
});

const ZFahrer = z.object({
  id: z.string(),
  name: z.string(),
  ist_informiert: z.boolean(),
  hat_dokumente: z.boolean(),
});

const ZBegleitfahrt = z.object({
  id: z.string(),
  fahrer: ZFahrer.nullable(),
  von: ZPunkt,
  nach: ZPunkt,
  fahrer_namen: z.array(z.string()),
  typ: z.string(),
  kennzeichen: z.string(),
  mehrwertsteuerpflichtig: z.boolean(),
});

const ZFahrzeug = z.object({
  kennzeichen: z.string(),
  fahrzeugtyp: z.string(),
  fahrer_namen: z.array(z.string()),
  fahrgestellnummer: z.string(),
  fahrer: z.array(ZFahrer),
});

const ZKunde = z.object({
  firma: z.string(),
  id: z.string(),
});

const ZAuftrag = z.object({
  id: z.string(),
  bemerkung: z.string(),
  abgeschlossen: z.boolean(),
  kunde: ZKunde,
  abholung: ZPunkt,
  anlieferung: ZPunkt,
  fahrzeug: ZFahrzeug,
  begleitfahrten: z.array(ZBegleitfahrt),
  abrechnungsnummer: z.string(),
  alle_fahrer_ids: z.array(z.string()),
  mehrwertsteuerpflichtig: z.boolean(),
});

export const ZPlan = z
  .object({
    auftraege: z.record(z.array(ZAuftrag)),
    fahrer: z.array(
      z.object({
        id: z.string(),
        anzeige_name: z.string(),
      }),
    ),
    kunden: z.array(
      z.object({
        id: z.string(),
        firma: z.string(),
      }),
    ),
    zeit: z.string(),
    wochen: z.record(z.number()),
    jahr: z.number(),
  })
  .optional();

export type IAuftrag = z.infer<typeof ZAuftrag>;
export type IPlan = z.infer<typeof ZPlan>;
export type IBegleitfahrt = z.infer<typeof ZBegleitfahrt>;
export type IFahrer = z.infer<typeof ZFahrer>;

const useDispositionsplan = () => {
  const client = useClient();
  const plan = ref<IPlan>();

  const { request, setQueryParam, queryParams } = client.get(
    "/query/dispositionsplan/",
  );
  if (!queryParams.value.sortierung) setQueryParam("sortierung", "erstellung");
  if (!queryParams.value.woche) setQueryParam("zeit", getMondayDate());
  const query = useGet(request, plan, queryParams);

  useValidation(plan, ZPlan);

  const wochenFahrer = computed<{ label: string; value: string }[]>(() => {
    if (!plan.value) return [];
    return [{ label: "------", value: "" }].concat(
      plan.value.fahrer.map((f) => ({
        label: f.anzeige_name,
        value: f.id,
      })),
    );
  });

  const wochenKunden = computed<{ label: string; value: string }[]>(() => {
    if (!plan.value) return [];
    return [{ label: "------", value: "" }].concat(
      plan.value.kunden.map((f) => ({
        label: f.firma,
        value: f.id,
      })),
    );
  });

  return {
    query,
    setQueryParam,
    queryParams,
    plan,
    wochenFahrer,
    wochenKunden,
  };
};

export default useDispositionsplan;

<script setup lang="ts">
import { FormGenerator } from "lorga-ui";
import useCmd from "@/composables/useCmd";
import { IFahrer } from "../api/useWochenplan";
import { wochenplanFahrerFields } from "../utils/wochenplan";
import { useAlertStore } from "@/store/alert";

const props = defineProps<{
  query: () => void;
  wochenplanId: string;
  fahrer: IFahrer;
}>();

const alertStore = useAlertStore();
const showSuccess = () => {
  alertStore.showSuccess("Verfügbarkeit erfolgreich abgespeichert");
};

const { commandRequest } = useCmd(props.query, showSuccess);

const fields = wochenplanFahrerFields;
</script>

<template>
  <FormGenerator
    submit="Speichern"
    :fields="fields"
    :request="commandRequest"
    :data="{
      action: 'wochenplan.verfuegbarkeit_anpassen',
      bemerkung: fahrer.bemerkung,
      wochenplan_id: wochenplanId,
      fahrer_id: fahrer.id,
      montag: fahrer.montag,
      dienstag: fahrer.dienstag,
      mittwoch: fahrer.mittwoch,
      donnerstag: fahrer.donnerstag,
      freitag: fahrer.freitag,
      samstag: fahrer.samstag,
      sonntag: fahrer.sonntag,
    }"
  />
</template>

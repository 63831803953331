import DispositionDashboard from "@/features/disposition/views/DispositionDashboard.vue";
import DispositionListenansicht from "@/features/disposition/views/DispositionListenansicht.vue";
import WochenplanDashboard from "./views/WochenplanDashboard.vue";
import WochenplanFahrer from "./views/WochenplanFahrer.vue";

// attention: do not add a slash in front of the paths as this will break the routing.
// For example the router will see it as a root path like '/dashboard' instead of '/whatever/dashboard'.
export const routes = [
  {
    path: "wochenansicht",
    component: DispositionDashboard,
    name: "disposition-dashboard",
  },
  {
    path: "listenansicht",
    component: DispositionListenansicht,
    name: "disposition-liste",
  },
  {
    path: "wochenplan",
    component: WochenplanDashboard,
    name: "wochenplan-dashboard",
  },
  {
    path: "wochenplan/fahrer/:wochenplanId/:fahrerId",
    component: WochenplanFahrer,
    name: "wochenplan-fahrer",
  },
];

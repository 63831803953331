<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{
  query: () => void;
  userId: string;
  userName: string;
}>();
const { query } = toRefs(props);

const fields: types.FormField[] = [
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
];

const { commandModalOpen, commandRequest } = useCmd(query);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Speichern"
      title="User Bearbeiten"
      :data="{
        action: 'user.update',
        name: userName,
        user_id: userId,
      }"
      :fields="fields"
      :request="commandRequest"
    />
    Bearbeiten
  </ButtonNormal>
</template>

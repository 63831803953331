<script setup lang="ts">
import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { TableGenerator } from "lorga-ui";
import { ref } from "vue";
import UserUpdate from "../actions/UserUpdate.vue";

interface IUser {
  id: string;
  email: string;
  name: string;
}

const users = ref<IUser[]>();
const client = useClient();
const { request } = client.get("/query/users/");
const query = useGet(request, users);

const head = [
  { name: "E-Mail", key: "email" },
  { name: "Name", key: "name" },
  { name: "", key: "actions" },
];

const addUserUrl = import.meta.env.VITE_API_URL + "/signup";
</script>

<template>
  <div class="p-5 px-6 bg-slate-500">
    <TableGenerator :head="head" :data="users">
      <template #head-actions>
        <a :href="addUserUrl" target="_blank" rel="noopener">User Hinzufügen</a>
      </template>
      <template #actions="{ i }">
        <UserUpdate :user-id="i.id" :user-name="i.name" :query="query" />
      </template>
    </TableGenerator>
  </div>
</template>

import { Ref, computed } from "vue";
import { IFahrerForm } from "./useFahrer";
import { types } from "lorga-ui";
import { IKundeForm } from "./useKunden";
import useBegleitfahrtFields from "./useBegleitfahrtFields";
import useFahrerFields from "./useFahrerFields";

const useAuftragFields = (
  kunden: Ref<IKundeForm[]>,
  fahrer: Ref<IFahrerForm[]>,
  begleittypen: Ref<string[]>,
) => {
  const { begleitfahrtFields } = useBegleitfahrtFields(begleittypen, fahrer);
  const { fahrerFields } = useFahrerFields(fahrer);

  const auftragFields = computed<types.FormField[]>(() => {
    const f: types.FormField[] = [
      {
        label: "Bemerkung",
        name: "bemerkung",
        type: "textarea",
        required: false,
      },
      {
        label: "Kunde",
        name: "kunde_id",
        options: kunden.value,
        type: "searchselect",
        required: true,
      },
      { label: "Von", name: "von", type: "text", required: true },
      { label: "Nach", name: "nach", type: "text", required: true },
      {
        label: "Abholdatum",
        name: "abholdatum",
        type: "datetime-local",
        required: true,
      },
      {
        name: "fahrer",
        type: "array",
        addButtonText: "Fahrer hinzufügen",
        removeButtonText: "Fahrer entfernen",
        fields: fahrerFields.value,
      },
      {
        label: "Kennzeichen",
        name: "kennzeichen",
        type: "text",
        required: false,
      },
      {
        label: "Fahrzeugtyp",
        name: "fahrzeugtyp",
        type: "text",
        required: false,
      },
      {
        label: "Fahrgestellnummer",
        name: "fahrgestellnummer",
        type: "text",
        required: false,
      },
      {
        label: "Abrechnungsnummer",
        name: "abrechnungsnummer",
        type: "text",
        required: false,
      },
      {
        label: "Mehrwertsteuerpflichtig",
        name: "mehrwertsteuerpflichtig",
        type: "singlecheckbox",
      },
      {
        name: "begleitfahrten",
        type: "array",
        addButtonText: "Begleitfahrt hinzufügen",
        removeButtonText: "Begleitfahrt entfernen",
        fields: begleitfahrtFields.value,
        defaultAmount: 0,
      },
    ];

    return f.filter((f) => f !== undefined);
  });

  return {
    auftragFields,
  };
};

export default useAuftragFields;

<script setup lang="ts">
import { SetQueryParam, QueryParams } from "@/api/client";
import { toRefs } from "vue";
import QueryBarFilterHint from "./QueryBarFilterHint.vue";

const props = defineProps<{
  setQueryParam: SetQueryParam;
  queryParams: QueryParams;
  filterHints: {
    label: string;
    name: string;
    type?: string;
    options?: {
      label: string;
      value: string | null | boolean | undefined | number | string[];
    }[];
    addons?: string[];
  }[];
}>();
const { setQueryParam, filterHints, queryParams } = toRefs(props);
</script>

<template>
  <div class="flex items-center space-x-4 print:hidden">
    <QueryBarFilterHint
      v-for="f in filterHints"
      :key="f.name"
      :filter-hint="f"
      :query-params="queryParams"
      :set-query-param="setQueryParam"
    />
  </div>
</template>

<script setup lang="ts">
import { SetQueryParam, QueryParams } from "@/api/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { computed, toRefs } from "vue";

const props = defineProps<{
  setQueryParam: SetQueryParam;
  queryParams: QueryParams;
  filterHint: {
    label: string;
    name: string;
    type?: string;
    options?: {
      label: string;
      value: string | null | boolean | undefined | number | string[];
    }[];
    addons?: string[];
  };
}>();
const { setQueryParam, filterHint, queryParams } = toRefs(props);

const submit = (
  key: string,
  value: string | null | boolean | undefined | number | string[],
) => {
  setQueryParam.value(key, value);
};

const prevValueOfFilterHint = computed(() => {
  const options = filterHint.value.options || [];
  const currentValue = queryParams.value[filterHint.value.name];
  const currentIndex = options.findIndex((o) => o.value === currentValue);
  if (currentIndex === -1) return null;
  const nextIndex = currentIndex - 1;
  if (nextIndex < 0) return null;
  return options[nextIndex].value;
});

const nextValueOfFilterHint = computed(() => {
  const options = filterHint.value.options || [];
  const currentValue = queryParams.value[filterHint.value.name];
  const currentIndex = options.findIndex((o) => o.value === currentValue);
  if (currentIndex === -1) return null;
  const nextIndex = currentIndex + 1;
  if (nextIndex >= options.length) return null;
  return options[nextIndex].value;
});
</script>

<template>
  <div class="flex items-center h-8 pl-2 border rounded bg-slate-200">
    <label
      class="text-sm font-medium text-slate-800"
      :for="`query-bar-${filterHint.name}`"
    >
      {{ filterHint.label }}:
    </label>
    <input
      v-if="filterHint.type === 'text'"
      :id="`query-bar-${filterHint.name}`"
      :value="queryParams[filterHint.name]"
      :name="filterHint.name"
      class="h-full py-0 pl-1 pr-0 ml-2 text-base border border-transparent bg-slate-100 focus:outline-none"
      @input="
        ($event) =>
          submit(filterHint.name, ($event.target as HTMLInputElement).value)
      "
    />
    <select
      v-else
      :id="`query-bar-${filterHint.name}`"
      :value="queryParams[filterHint.name]"
      :name="filterHint.name"
      class="py-1 pl-1 pr-0.5 ml-2 text-base border border-transparent bg-slate-100 focus:outline-none"
      @change="
        submit(filterHint.name, ($event.target as HTMLInputElement).value)
      "
    >
      <template v-for="option in filterHint.options" :key="option">
        <option :value="option.value">{{ option.label }}</option>
      </template>
    </select>
    <button
      v-if="filterHint.addons?.includes('prev') && prevValueOfFilterHint"
      class="flex items-center justify-center pl-[2px] w-8 h-8 rounded-r hover:bg-slate-100/30 border-slate-500 text-slate-500 bg-slate-200 focus:outline-none active:bg-slate-100/70"
      type="button"
      @click="submit(filterHint.name, prevValueOfFilterHint)"
    >
      <ChevronLeftIcon class="w-6 h-6" />
    </button>
    <button
      v-if="filterHint.addons?.includes('next') && nextValueOfFilterHint"
      class="flex items-center justify-center pl-[2px] w-8 h-8 rounded-r hover:bg-slate-100/30 border-slate-500 text-slate-500 bg-slate-200 focus:outline-none active:bg-slate-100/70"
      type="button"
      @click="submit(filterHint.name, nextValueOfFilterHint)"
    >
      <ChevronRightIcon class="w-6 h-6" />
    </button>
  </div>
</template>

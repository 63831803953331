import { formatDate, formatTime } from "@/utils/date";
import { isObjectAndEmpty, isObjectAndHasAttr } from "@/utils/object";

interface IBegleitfahrt {
  von: {
    datum: string | null;
  };
}

export const getTime = (
  begleitfahrt: IBegleitfahrt,
  auftragVonDatum: string | null,
) => {
  if (!begleitfahrt.von.datum || !auftragVonDatum) return "";
  if (
    new Date(begleitfahrt.von.datum).getDate() ===
    new Date(auftragVonDatum).getDate()
  ) {
    return formatTime(begleitfahrt.von.datum);
  }
  return formatDate(begleitfahrt.von.datum, "short", "short");
};

export const checkBegleitfahrten = (data: unknown) => {
  if (isObjectAndHasAttr(data, "begleitfahrten")) {
    const d = data as {
      begleitfahrten: unknown[];
      von?: unknown;
      nach?: unknown;
      abholdatum?: unknown;
    };
    const begleitfahrten = d.begleitfahrten.map((b: unknown) => {
      if (isObjectAndEmpty(b))
        return {
          abholdatum: d.abholdatum,
          von: d.von,
          nach: d.nach,
          mehrwertsteuerpflichtig: true,
        };
      return b;
    });
    d.begleitfahrten = begleitfahrten;
  }
};

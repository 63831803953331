<script setup lang="ts">
import useClient from "@/api/client";
import useCommand from "@/composables/useCommand";
import { ButtonNormal, ModalDelete } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{
  query: () => void;
  fahrerId: string;
  wochenplanId: string;
  fahrerName: string;
}>();
const { query, fahrerId } = toRefs(props);

const client = useClient();

const { request } = client.post("/command/");
const { commandRequest, commandModalOpen } = useCommand(request, query.value);
</script>

<template>
  <ButtonNormal kind="delete" @click="commandModalOpen = true">E</ButtonNormal>
  <ModalDelete
    v-model="commandModalOpen"
    title="Fahrer Entfernen"
    submit="Entfernen"
    :request="commandRequest"
    :data="{
      action: 'wochenplan.fahrer_entfernen',
      fahrer_id: fahrerId,
      wochenplan_id: wochenplanId,
    }"
  >
    Willst du wirklich Fahrer '{{ fahrerName }}' entfernen?
  </ModalDelete>
</template>

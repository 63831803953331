<script setup lang="ts">
import useClient from "@/api/client";
import useCommand from "@/composables/useCommand";
import {
  IAuftrag,
  IST_ABGERECHNET,
} from "@/features/abrechnung/api/useAbrechnungsplan";
import { ButtonNormal } from "lorga-ui";
import { computed, toRefs } from "vue";

const props = defineProps<{
  query: () => void;
  auftrag: IAuftrag;
  fahrerId: string;
}>();
const { query, auftrag, fahrerId } = toRefs(props);

const client = useClient();

const { request } = client.post("/command/");
const { commandRequest } = useCommand(request, query);

const fahrer = computed(() => {
  const f1 = auftrag.value.fahrzeug.abzurechnende_fahrer;
  const f2 = auftrag.value.begleitfahrten.flatMap(
    (bf) => bf.abzurechnende_fahrer,
  );
  const f3 = [...f1, ...f2];
  const f4 = f3.filter((f) => f.abrechnungsstatus !== IST_ABGERECHNET);
  return f4;
});

const isDisabled = computed(() => {
  return !fahrer.value.some((f) => f.id === fahrerId.value);
});

const action = () => {
  commandRequest({
    action: "auftrag.fahrer_abrechnen",
    fahrer_id: fahrerId.value,
    auftrag_id: auftrag.value.id,
  }).then(() => {
    query.value();
  });
};
</script>

<template>
  <ButtonNormal :disabled="isDisabled" kind="action" @click="action">
    <template v-if="isDisabled">_</template>
    <template v-else>A</template>
  </ButtonNormal>
</template>

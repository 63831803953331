import useClient from "@/api/client";
import { IKunde } from "../types/kunde";
import { Ref, computed, ref, watch } from "vue";
import useQuery from "@/composables/useQuery";

export interface IKundeForm {
  name: string;
  value: string;
}

export function useKunden(when?: Ref<boolean>) {
  const client = useClient();
  const kunden = ref<IKunde[]>([]);
  const { request } = client.get("/query/kunden/");
  const query = useQuery(request, kunden);

  if (when)
    watch(when, (newVal) => {
      if (newVal) query();
    });
  else query();

  const kundenForm = computed<IKundeForm[]>(() => {
    const k: IKundeForm[] = kunden.value.map((k) => ({
      name: k.formularname,
      value: k.id,
    }));
    return k;
  });

  return {
    kunden,
    kundenForm,
  };
}

<script setup lang="ts">
import { ButtonNormal, ModalForm, types } from "lorga-ui";
import useCmd from "@/composables/useCmd";
import { useEinstellung } from "../api/useEinstellung";

const { einstellung, query } = useEinstellung();

const { commandRequest, commandModalOpen } = useCmd(query);

const fields: types.FormField[] = [
  {
    label: "Template",
    name: "email_template",
    type: "textarea",
    required: true,
    helptext:
      "Verfügbare Variablen: {woche.woche}, {woche.montag}, {woche.sonntag}, {link}, {fahrer.vorname}, {fahrer.nachname}, {fahrer.anzeige_name}, {fahrer.email}.",
  },
];
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    Wochenplan Email Anpassen
  </ButtonNormal>
  <ModalForm
    ref="modal"
    v-model="commandModalOpen"
    title="Verfügbarkeit Anpassen"
    submit="Anpassen"
    :fields="fields"
    :request="commandRequest"
    width="max-w-3xl"
    :data="{
      action: 'wochenplan.email_anpassen',
      email_template: einstellung?.email_template,
    }"
  />
</template>

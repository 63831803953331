<script setup lang="ts">
import useClient from "@/api/client";
import useCommand from "@/composables/useCommand";
import { ButtonNormal, ModalDelete } from "lorga-ui";
import { toRefs } from "vue";

const props = defineProps<{
  query: () => void;
  auftragId: string;
  auftragText: string;
}>();
const { query, auftragId } = toRefs(props);

const client = useClient();

const { request } = client.post("/command/");
const { commandRequest, commandModalOpen } = useCommand(request, query.value);
</script>

<template>
  <ButtonNormal kind="delete" @click="commandModalOpen = true">E</ButtonNormal>
  <ModalDelete
    v-model="commandModalOpen"
    title="Auftrag Entfernen"
    submit="Entfernen"
    :request="commandRequest"
    :data="{
      action: 'auftrag.entfernen',
      auftrag_id: auftragId,
    }"
  >
    Willst du wirklich Auftrag '{{ auftragText }}' entfernen?
  </ModalDelete>
</template>

<template>
  <StartComponent title="Willkommen bei Gerber Logistik" :links="links" />
</template>

<script setup lang="ts">
import StartComponent from "@/components/StartComponent.vue";

const links = [
  { name: "Disposition", toName: "disposition-dashboard" },
  { name: "Testing", toName: "testing-dashboard" },
];
</script>

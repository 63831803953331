<script setup lang="ts">
import { ButtonNormal, ModalConfirm } from "lorga-ui";
import { toRefs } from "vue";
import useCmd from "@/composables/useCmd";

const props = defineProps<{ query: () => void; woche: string }>();
const { woche, query } = toRefs(props);

const { commandRequest, commandModalOpen } = useCmd(query.value);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    Wochenplan Erstellen
  </ButtonNormal>
  <ModalConfirm
    v-model="commandModalOpen"
    title="Wochenplan Erstellen"
    submit="Erstellen"
    :request="commandRequest"
    :data="{
      action: 'wochenplan.erstellen',
      woche: woche,
    }"
  >
    <p>Der Wochenplan der vorherigen Woche wird hierbei kopiert.</p>
  </ModalConfirm>
</template>

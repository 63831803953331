<script setup lang="ts">
import { computed } from "vue";
import useAbrechnungsplan from "../api/useAbrechnungsplan";
import QueryBar from "@/components/QueryBar.vue";
import DispositionsGrid from "@/features/disposition/components/DispositionsGrid.vue";
import AbrechnungAuftrag from "../components/AbrechnungAuftrag.vue";

const { plan, setQueryParam, queryParams, query } = useAbrechnungsplan();

const filterHints = computed(() => [
  {
    label: "Woche",
    name: "zeit",
    type: "select",
    options: plan.value
      ? Object.entries(plan.value?.wochen).map(([date, week]) => ({
          label: week.toString(),
          value: date,
        }))
      : [],
    addons: ["prev", "next"],
  },
]);
</script>

<template>
  <div class="p-10 bg-gray-500 print:p-0 print:bg-white">
    <QueryBar
      class=""
      :filter-hints="filterHints"
      :set-query-param="setQueryParam"
      :query-params="queryParams"
    />
    <div
      v-if="plan?.ist_abgerechnet"
      class="px-5 py-2 mt-5 text-green-900 bg-green-100 border-2 border-green-500"
    >
      Alle Aufträge in dieser Woche sind abgerechnet.
    </div>
    <DispositionsGrid :plan="plan" mini class="mt-5">
      <template #auftrag="{ auftrag }">
        <AbrechnungAuftrag :auftrag="auftrag" :query="query" />
      </template>
    </DispositionsGrid>
  </div>
</template>

export const getFahrzeugColor = (typ: string) => {
  if (typ.includes("LTM") || typ.includes("LTC") || typ.includes("LG"))
    return "bg-amber-500/20 text-amber-900";
  return "text-gray-800";
};

export const getBegleittypColor = (typ: string) => {
  if (typ.includes("BF3")) return "bg-orange-400/20 text-red-900";
  if (typ.includes("BF") || typ.includes("Verwaltungshelfer"))
    return "bg-red-400/20 text-red-900";
  if (typ.includes("Hilfspolizei")) return "bg-cyan-500/20 text-cyan-900";
  if (typ.includes("Polizei")) return "bg-blue-500/20 text-blue-900";
  if (typ.includes("Beifahrer")) return "bg-green-500/20 text-green-900";
  return "text-gray-800";
};

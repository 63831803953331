<script setup lang="ts">
import { useRoute } from "vue-router";
import { useWochenplanFahrer } from "../api/useWochenplanFahrer";
import VerfuegbarkeitForm from "../actions/VerfuegbarkeitForm.vue";
import { CircleLoader } from "lorga-ui";
import { formatDate } from "@/utils/date";

const route = useRoute();

const wochenplanId = route.params.wochenplanId as string;
const fahrerId = route.params.fahrerId as string;
const { fahrer, query, data } = useWochenplanFahrer(wochenplanId, fahrerId);
</script>

<template>
  <div
    class="flex items-center justify-center w-full min-h-screen bg-slate-500"
  >
    <div class="max-w-2xl w-full p-5 mx-auto prose bg-white mb-[30vh]">
      <h1>Wochenplan für Woche {{ data?.woche }}</h1>
      <p class="mb-0">
        <b>
          {{ fahrer?.vorname }} {{ fahrer?.nachname }}:
          {{ formatDate(data?.von) }} - {{ formatDate(data?.bis) }}
        </b>
      </p>
      <p class="mt-0">
        Bitte alle verfügbaren Tage anhaken und dann abspeichern.
      </p>
      <VerfuegbarkeitForm
        v-if="fahrer"
        :query="query"
        :wochenplan-id="wochenplanId"
        :fahrer="fahrer"
      />
      <CircleLoader v-else />
    </div>
  </div>
</template>

import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { ref } from "vue";
import { z } from "zod";

const ZEinstellung = z.object({
  email_template: z.string(),
});

type IEinstellung = z.infer<typeof ZEinstellung>;

export function useEinstellung() {
  const client = useClient();
  const { request } = client.get("/query/wochenplan/einstellung/");

  const einstellung = ref<IEinstellung>();

  const query = useGet(request, einstellung);

  useValidation(einstellung, ZEinstellung);

  return {
    query,
    einstellung,
  };
}

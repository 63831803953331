<template>
  <StartComponent title="Welcome to Testing" :links="links" :back="back" />
</template>

<script setup lang="ts">
import StartComponent from "@/components/StartComponent.vue";

const links = [{ name: "Go to Alert Testing", toName: "alert-testing" }];

const back = { name: "Go back to Start", toName: "index" };
</script>

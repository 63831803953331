<script setup lang="ts">
import { ButtonNormal, ModalUpdate } from "lorga-ui";
import { toRefs } from "vue";
import { useFahrer } from "../composables/useFahrer";
import { useBegleittypen } from "../composables/useBegleittypen";
import useAuftragFields from "../composables/useAuftragFields";
import { useKunden } from "../composables/useKunden";
import { checkBegleitfahrten } from "../utils/begleitfahrt";
import useCmd from "@/composables/useCmd";

interface IFahrer {
  id: string;
  ist_informiert: boolean;
  hat_dokumente: boolean;
}

const props = defineProps<{
  query: () => void;
  kundeId: string;
  auftragId: string;
  fahrer: IFahrer[];
  von: string;
  nach: string;
  bemerkung: string;
  abholdatum: string;
  anlieferdatum: string | null;
  abrechnungsnummer: string;
  kennzeichen: string | null;
  fahrgestellnummer: string | null;
  fahrzeugtyp: string | null;
  mehrwertsteuerpflichtig: boolean;
}>();
const { query, fahrer, abholdatum, von, nach, mehrwertsteuerpflichtig } =
  toRefs(props);

const { commandRequest, commandModalOpen } = useCmd(query.value);

const { fahrerForm } = useFahrer(commandModalOpen);
const { kundenForm } = useKunden(commandModalOpen);

const { begleittypen } = useBegleittypen(commandModalOpen);

const { auftragFields } = useAuftragFields(
  kundenForm,
  fahrerForm,
  begleittypen,
);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">B</ButtonNormal>
  <ModalUpdate
    ref="modal"
    v-model="commandModalOpen"
    width="max-w-3xl"
    title="Auftrag Bearbeiten"
    submit="Speichern"
    :fields="auftragFields"
    :request="commandRequest"
    :data="{
      action: 'auftrag.bearbeiten',
      kunde_id: kundeId,
      auftrag_id: auftragId,
      fahrer: fahrer,
      von: von,
      nach: nach,
      fahrzeugtyp: fahrzeugtyp,
      abholdatum: abholdatum,
      anlieferdatum: anlieferdatum,
      abrechnungsnummer: abrechnungsnummer,
      kennzeichen: kennzeichen,
      fahrgestellnummer: fahrgestellnummer,
      bemerkung: bemerkung,
      mehrwertsteuerpflichtig: mehrwertsteuerpflichtig,
    }"
    @change="checkBegleitfahrten"
  />
</template>

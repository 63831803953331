import { useErrorHandling } from "@/api/errors";
import { Ref, watch } from "vue";

function useQuery<
  Type,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  Fn extends (...args: any[]) => Promise<Type>,
>(
  getFunc: Fn,
  obj: Ref<Type | undefined>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...watching: Ref<any>[]
): () => Promise<void> {
  const { handleQueryError } = useErrorHandling();

  const getRequest = () => {
    return getFunc()
      .then((newItem) => {
        obj.value = newItem;
      })
      .catch(handleQueryError);
  };

  watch(watching, () => {
    obj.value = undefined;
    getRequest();
  });

  return getRequest;
}

export default useQuery;

import { Ref, ref, unref } from "vue";
import { useErrorHandling } from "@/api/errors";

type VoidFn = () => void;

export default function useCommand<
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  RFn extends (...args: any[]) => Promise<void | any>,
>(
  requestFunc: RFn,
  ...queries: (VoidFn | Ref<VoidFn>)[]
): {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  commandRequest: (data: any) => Promise<any>;
  commandModalOpen: Ref<boolean>;
} {
  const commandModalOpen = ref(false);

  const { handleCommandError } = useErrorHandling();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const commandRequest = (data: any) => {
    return requestFunc(data)
      .then((d) => {
        queries.forEach((qf) => unref(qf)());
        commandModalOpen.value = false;
        return d;
      })
      .catch(handleCommandError);
  };

  return {
    commandRequest,
    commandModalOpen,
  };
}

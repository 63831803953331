<script setup lang="ts">
import { TableGenerator } from "lorga-ui";
import FahrerErstellen from "../actions/FahrerErstellen.vue";
import FahrerBearbeiten from "../actions/FahrerBearbeiten.vue";
import FahrerLoeschen from "../actions/FahrerLoeschen.vue";
import SearchBar from "@/components/SearchBar.vue";
import { useFahrer } from "../api/useFahrer";

const { fahrer, query, queryParams, setQueryParam } = useFahrer();

const head = [
  { name: "PK", key: "pk_nummer" },
  { name: "Name", key: "anzeige_name" },
  { name: "E-Mail", key: "email" },
  { name: "Typ", key: "typ" },
  { name: "", key: "action" },
];
</script>

<template>
  <div class="p-5 px-6 bg-slate-500">
    <SearchBar :set-query-param="setQueryParam" :query-params="queryParams" />
    <TableGenerator :head="head" :data="fahrer">
      <template #head-action><FahrerErstellen :query="query" /></template>
      <template #action="{ i }">
        <FahrerBearbeiten
          :query="query"
          :fahrer-pk="i.pk_nummer"
          :fahrer-typ="i.typ"
          :fahrer-email="i.email"
          :fahrer-id="i.id"
          :fahrer-name="i.anzeige_name"
        />
        <FahrerLoeschen
          :query="query"
          :fahrer-id="i.id"
          :fahrer-name="i.anzeige_name"
        />
      </template>
    </TableGenerator>
  </div>
</template>

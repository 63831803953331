import { Ref, computed } from "vue";
import { IFahrerForm } from "./useFahrer";
import { types } from "lorga-ui";

const useFahrerFields = (fahrer: Ref<IFahrerForm[]>) => {
  const fahrerFields = computed<types.FormField[]>(() => {
    const f: types.FormField[] = [
      {
        label: "Fahrer",
        name: "id",
        type: "searchselect",
        required: false,
        options: fahrer.value,
      },
      {
        label: "Ist Informiert",
        name: "ist_informiert",
        type: "singlecheckbox",
      },
      {
        label: "Hat Dokumente",
        name: "hat_dokumente",
        type: "singlecheckbox",
      },
    ];
    return f;
  });

  return {
    fahrerFields,
  };
};

export default useFahrerFields;

<script setup lang="ts">
import { FormToggle } from "lorga-ui";
import { ref, watch } from "vue";

const mini = ref<boolean>();

const emit = defineEmits(["change"]);

watch(mini, () => {
  emit("change", mini.value);
});
</script>

<template>
  <div class="inline-block">
    <div class="flex items-center space-x-2">
      <div class="text-sm font-medium text-gray-700">Mini-Ansicht</div>
      <FormToggle v-model="mini" label="" />
    </div>
  </div>
</template>

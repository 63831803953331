import { watch } from "vue";
import { z } from "zod";

export const useValidation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: z.ZodNullable<any> | z.ZodObject<any, any> | z.ZodOptional<any>,
) => {
  // const result = schema.safeParse(data);
  // if (!result.success) console.error(result.error);
  watch(data, (newData) => {
    const result = schema.safeParse(newData);
    if (!result.success) console.error("ZOD ERROR", result.error.issues);
  });
};

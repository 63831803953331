<script setup lang="ts">
import { computed, ref } from "vue";
import DispositionsAuftrag from "../components/DispositionsAuftrag.vue";
import AuftragErstellen from "../actions/AuftragErstellen.vue";
import ViewToggle from "../components/ViewToggle.vue";
import DispositionsAuftragMini from "../components/DispositionsAuftragMini.vue";
import SearchBar from "@/components/SearchBar.vue";
import QueryBar from "@/components/QueryBar.vue";
import PrintPage from "../actions/PrintPage.vue";
import useDispositionsplan from "../api/useDispositionsplan";
import DispositionsGrid from "../components/DispositionsGrid.vue";
import AccessExportGenerieren from "../actions/AccessExportGenerieren.vue";

const { plan, wochenFahrer, wochenKunden, queryParams, setQueryParam, query } =
  useDispositionsplan();

const hints = computed(() => [
  {
    label: "Fahrer",
    name: "fahrer",
    type: "select",
    options: wochenFahrer.value,
  },
  {
    label: "Kunde",
    name: "kunde",
    type: "select",
    options: wochenKunden.value,
  },
]);

const filterHints = computed(() => [
  {
    label: "Sortierung",
    name: "sortierung",
    type: "select",
    options: [
      { label: "Erstellung", value: "erstellung" },
      { label: "Abfahrtszeit", value: "abholung" },
    ],
  },
  {
    label: "Woche",
    name: "zeit",
    type: "select",
    options: plan.value
      ? Object.entries(plan.value?.wochen).map(([date, week]) => ({
          label: week.toString(),
          value: date,
        }))
      : [],
    addons: ["prev", "next"],
  },
]);

const mini = ref(false);

// update on change
var eventSource = new EventSource(
  `${import.meta.env.VITE_API_URL}/stream/dispositionsplan/`,
  {
    withCredentials: true,
  },
);
eventSource.onmessage = function (e) {
  if (e.data.includes("CHANGE")) query();
};
</script>

<template>
  <div class="p-5 px-6 bg-gray-500 print:p-0 print:bg-white">
    <h1 class="sr-only">Disposition</h1>
    <div class="flex flex-wrap -mt-2 gap-x-6">
      <SearchBar
        disable-search
        :hints="hints"
        :set-query-param="setQueryParam"
        :query-params="queryParams"
      />
      <QueryBar
        class="mt-2"
        :filter-hints="filterHints"
        :set-query-param="setQueryParam"
        :query-params="queryParams"
      />
    </div>
    <div
      class="inline-block pl-2.5 pr-3 mt-2 mb-5 bg-gray-100 rounded print:hidden"
    >
      <div class="flex items-center h-8">
        <div class="pr-3 leading-none">
          <ViewToggle @change="mini = $event" />
        </div>
        <div class="w-px h-full bg-gray-300"></div>
        <div class="pl-3 space-x-3">
          <PrintPage />
          <AccessExportGenerieren :date="plan?.zeit" />
        </div>
      </div>
    </div>
    <DispositionsGrid :plan="plan" :mini="mini">
      <template #top="{ datum }">
        <AuftragErstellen :query="query" :date="`${datum}T00:00`" />
      </template>
      <template #auftrag="{ auftrag }">
        <DispositionsAuftragMini v-if="mini" :auftrag="auftrag" />
        <DispositionsAuftrag v-else :auftrag="auftrag" :query="query" />
      </template>
    </DispositionsGrid>
  </div>
</template>

<style lang="pcss">
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 16px;
  padding: 10px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-gray-400;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-300 border-gray-100 border-2;
}
.custom-scrollbar.vertical::-webkit-scrollbar-thumb {
  border-top: 8px solid #9ba2ae;
  border-bottom: 8px solid #9ba2ae;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-300/70;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
</style>

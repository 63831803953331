import FahrerListe from "@/features/management/views/FahrerListe.vue";
import KundenListe from "@/features/management/views/KundenListe.vue";
import UsersListe from "@/features/management/views/UsersListe.vue";

// attention: do not add a slash in front of the paths as this will break the routing.
// For example the router will see it as a root path like '/dashboard' instead of '/whatever/dashboard'.
export const routes = [
  { path: "fahrer", component: FahrerListe, name: "management-fahrer" },
  { path: "kunden", component: KundenListe, name: "management-kunden" },
  { path: "users", component: UsersListe, name: "management-users" },
];
